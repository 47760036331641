<template>
  <div class="pb-20">

    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Incomplete Applications</h2>
        <router-link :to="{ name: 'users-new'}" class="text-sm text-gray-700">
          + Add new user
        </router-link>
      </div>
    </div>
    
    <nav class="border border-b-2 border-blue-200 px-8 pt-1">
      <ul class="flex flex-wrap items-center w-full">
        <li class="-mb-2px pr-20 relative">
          <a
            href="#!"
            class="block border-b-2 cursor-pointer duration-300 font-bold pt-3 pb-4 relative text-blue-400 hover:text-gray-600 text-sm"
            :class="{ 'border-blue-500 text-blue-500 hover:text-blue-500': tab === 'one' }"
            @click.prevent="setTab('one')"
          >
            <span class="mr-2">Incomplete 1</span>
          </a>
          <span class="absolute h-3 w-1 border-r-2 border-blue-200 right-10 top-1/2 transform -translate-y-1/2" />
        </li>
        <li class="-mb-2px pr-20 relative">
          <a
            href="#!"
            class="block border-b-2 cursor-pointer duration-300 font-bold pt-3 pb-4 relative text-blue-400 hover:text-gray-600 text-sm"
            :class="{ 'border-blue-500 text-blue-500 hover:text-blue-500': tab === 'zero' }"
            @click.prevent="setTab('zero')"
          >
            <span class="mr-2">Incomplete 0</span>
          </a>
        </li>
      </ul>
    </nav>
    
    <component :is="tab" />

  </div>
</template>

<script>
  export default {
    components: {
      one: () => import('./One'),
      zero: () => import('./Zero')
    },
    data () {
      return {
        tab: 'one',
      }
    },
    mounted() {
      this.tab = this.$route.query.tab || this.tab;
    },
    methods: {
      setTab(tab) {
        this.tab = tab;
        this.$router.push({ ...this.$route, query: { ...this.$route.query, tab } });
      },
    }
  }
</script>
